.btn {
  padding: 15px 20px;
  border: 3px solid #2191fb;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  border-radius: 3px;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
}

.blog-wrapper {
  padding-bottom: 220px;
}

.contact-icon {
  width: 16px;
  height: 16px;
}

.contact-icon.blog {
  width: 12px;
  height: 12px;
}

.home-container {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.post-body {
  display: block;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.thick-line {
  max-width: 69px;
  min-height: 13px;
  margin-bottom: 30px;
  background-color: #f4b9bf;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

@media screen and (max-width: 991px) {
  .btn {
    padding: 12px 18px;
    font-size: 15px;
  }
  .home-container {
    max-width: 100%;
  }
  .post-body {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .btn {
    padding: 11px 16px;
  }
  .post-body {
    padding-right: 0px;
    padding-left: 0px;
  }
  .thick-line {
    max-width: 52px;
    min-height: 8px;
    margin-bottom: 16px;
    background-color: #f4b9bf;
  }
  .w-layout-grid {
    grid-row-gap: 64px;
  }
}

@media screen and (max-width: 479px) {
  .home-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .post-body {
    padding-right: 0px;
    padding-left: 0px;
  }
  .w-layout-grid {
    grid-row-gap: 48px;
  }
}
