.heading-10 {
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 28px;
  line-height: 1.4em;
  font-weight: 600;
}

.image-12 {
  display: block;
  max-width: 80px;
  margin-right: auto;
  margin-left: auto;
}

.link-style {
  border-bottom: 2px solid #2191fb;
  color: #333;
  text-decoration: none;
}

.link-style:hover {
  background-color: rgba(33, 145, 251, 0.25);
}

.list-item {
  padding-bottom: 20px;
  text-align: left;
}

.text-block-14 {
  padding-top: 7px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  line-height: 1.6em;
  text-decoration: none;
}

.unordered-list {
  padding-top: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  text-align: left;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 340px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: color 300ms ease, background-color 300ms ease;
  transition: color 300ms ease, background-color 300ms ease;
}

@media screen and (max-width: 479px) {
  .heading-10 {
    font-size: 28px;
  }
  .list-item {
    padding-bottom: 15px;
  }
  .utility-page-content {
    padding: 0px 17px;
  }
}