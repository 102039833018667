.blog-category-heading {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 42px;
  line-height: 1.2em;
  font-weight: 600;
  text-align: left;
}

.blog-header {
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
}

.blog-page-image {
  border-radius: 5px;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.blog-page-image:hover {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

.blog-post-thumbnails {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ebebeb;
}

.body-6 {
  color: #333;
  padding-top: 40px;
}

.collection-list {
  padding-bottom: 20px;
}

.column-33 {
  display: block;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.columns-8 {
  padding: 0px 20px;
}

.container-39 {
  border-radius: 15px;
  background-color: #f7f7f7;
}

.div-block-79 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 339px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.heading-24 {
  padding-top: 100px;
  padding-bottom: 20px;
}

.image-27 {
  padding-left: 40px;
}

.link-block-2 {
  overflow: hidden;
}

.post-header-link {
  display: block;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  text-decoration: none;
}

.post-snippets {
  display: block;
  max-width: 700px;
  margin: 40px auto 120px;
}

.post-snippets.metrics {
  margin-bottom: 20px;
}

.post-summary {
  font-family: 'Source Sans Pro', sans-serif;
  color: #888;
  font-size: 16px;
  line-height: 1.6em;
}

.post-title {
  margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 42px;
  line-height: 1.3em;
  text-align: left;
}

.post-title.thumbnail {
  margin-top: 0px;
  padding-bottom: 20px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 24px;
  line-height: 1.4em;
  font-weight: 600;
}

.post-title.thumbnail:hover {
  color: #2191fb;
  text-decoration: none;
}

.read-more {
  display: block;
  max-width: 90px;
  margin-top: 10px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  text-decoration: none;
}

.read-more:hover {
  color: #2191fb;
}

.right-arrow {
  display: inline-block;
  max-height: 12px;
  margin-bottom: 3px;
  padding-left: 5px;
}

.right-arrow:hover {
  color: #2191fb;
}

.section-15 {
  padding-top: 60px;
}

.text-block-11 {
  display: inline;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-family: 'Source Sans Pro', sans-serif;
  color: #2191fb;
  font-size: 16px;
  font-weight: 600;
}

.text-block-11:hover {
  color: rgba(33, 145, 251, 0.65);
}

@media screen and (max-width: 991px) {
  .blog-category-heading {
    font-size: 36px;
  }
  .collection-list {
    padding-right: 15px;
    padding-left: 15px;
  }
  .div-block-79 {
    height: 260px;
  }
  .image-27 {
    margin-bottom: 0px;
  }
  .post-title {
    font-size: 38px;
    line-height: 1.3em;
  }
  .post-title.thumbnail {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .blog-page-image {
    padding-right: 10px;
    padding-left: 10px;
  }
  .blog-post-thumbnails {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .collection-list {
    padding-right: 25px;
    padding-left: 25px;
  }
  .column-9 {
    padding-top: 12px;
    padding-bottom: 0px;
  }
  .column-34 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .columns-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .container-39 {
    border-radius: 0px;
  }
  .div-block-79 {
    height: auto;
    padding-top: 40px;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .heading-24 {
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .image-27 {
    position: static;
    max-width: 70%;
    padding-left: 0px;
  }
  .post-summary {
    font-size: 16px;
  }
  .post-title {
    font-size: 32px;
    line-height: 1.2em;
  }
  .post-title.thumbnail {
    font-size: 24px;
    line-height: 1.2em;
  }
  .section-15 {
    padding-top: 20px;
  }
  .text-block-11 {
    padding-top: 5px;
  }
}

@media screen and (max-width: 479px) {
  .blog-category-heading {
    font-size: 30px;
  }
  .blog-page-image {
    padding-right: 0px;
    padding-left: 0px;
  }
  .blog-post-thumbnails {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .collection-list {
    padding-right: 20px;
    padding-left: 20px;
  }
  .column-34 {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .image-27 {
    max-width: 80%;
  }
  .post-summary {
    font-size: 15px;
    line-height: 1.5em;
  }
  .post-title {
    font-size: 26px;
  }
  .post-title.thumbnail {
    font-size: 22px;
  }
  .text-block-11 {
    padding-top: 4px;
  }
}
