.navbar {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.nav-menu {
  -o-object-fit: fill;
  object-fit: fill;
}

.nav-link {
  padding: 10px 15px;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: none;
}

.nav-link:hover {
  border-radius: 3px;
  background-color: #2191fb;
  color: #fff;
}

.nav-link.w--current {
  border-bottom-style: none;
  border-bottom-color: #000;
  color: #2191fb;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: none;
}

.nav-link.w--current:hover {
  color: #fff;
}

.nav-link.home {
  margin-top: 2px;
  padding: 10px 15px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.nav-link.home:hover {
  border-radius: 3px;
}

.navbar-menu {
  background-color: transparent;
  color: #333;
}

.image {
  margin-top: 7px;
}

@media screen and (max-width: 991px) {
  .nav-link {
    margin-bottom: 5px;
    background-color: #fff;
    text-align: center;
  }
  .nav-link.w--current {
    padding: 15px;
  }
  .nav-link.home {
    margin-bottom: 5px;
    padding: 15px;
  }
  .navbar {
    padding: 0 10px 0 20px;
    background-color: #fff;
  }
  .navbar-menu {
    background-color: transparent;
    color: #333;
  }
  .menu-button {
    display: none;
    background-color: transparent;
  }
  .menu-button.w--open {
    background-color: transparent;
    color: hsla(0, 0%, 100%, 0.81);
  }
  .nav-menu {
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.4);
    color: transparent;
  }
  .image {
    margin-top: 17px;
  }
}

@media screen and (max-width: 479px) {
  .navbar {
    padding: 0px;
  }
}