.body-2 {
  font-family: 'Open Sans', sans-serif;
}

.container-11 {
  padding-bottom: 149px;
}

.privacy-h1 {
  margin-top: 0px;
  margin-bottom: 13px;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 38px;
  font-weight: 700;
  text-align: left;
}

.privacy-rich-text {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.privacy-rich-text p {
  margin-bottom: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #333;
  font-size: 19px;
  line-height: 1.6em;
  font-weight: 400;
}

.privacy-rich-text h2 {
  margin-top: 104px;
  margin-bottom: 33px;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 34px;
  line-height: 1.2em;
  font-weight: 800;
}

.privacy-rich-text h4 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-family: 'Source Serif Pro', sans-serif;
  color: #333;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 600;
}

.privacy-rich-text h3 {
  margin-top: 39px;
  margin-bottom: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.privacy-rich-text blockquote {
  margin-top: 0px;
  margin-bottom: 0px;
  border-style: none;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 400;
}

.privacy-rich-text li {
  padding-bottom: 5px;
  font-size: 19px;
  line-height: 1.6em;
  text-decoration: none;
}

.privacy-rich-text a {
  border-bottom: 2px solid #2191fb;
  color: #333;
  text-decoration: none;
}

.privacy-rich-text a:hover {
  background-color: rgba(33, 145, 251, 0.25);
}

.privacy-title {
  display: block;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 100px;
  padding-bottom: 30px;
}

.section-16 {
  padding-top: 60px;
}

.thick-line.orange {
  max-width: 59px;
  min-height: 10px;
  background-color: #ffb4a7;
}

@media screen and (max-width: 991px) {
  .container-11 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) {
  .container-11 {
    padding-right: 25px;
    padding-left: 25px;
  }
  .privacy-h1 {
    font-size: 34px;
  }
  .privacy-rich-text {
    font-size: 16px;
  }
  .privacy-rich-text p {
    font-size: 16px;
  }
  .privacy-rich-text h2 {
    margin-top: 70px;
    margin-bottom: 26px;
    padding-top: 0px;
    font-size: 30px;
  }
  .privacy-rich-text h3 {
    margin-top: 25px;
    margin-bottom: 8px;
    font-size: 20px;
  }
  .privacy-rich-text blockquote {
    font-size: 16px;
  }
  .thick-line.orange {
    max-width: 60px;
    min-height: 8px;
  }
}

@media screen and (max-width: 479px) {
  .container-11 {
    padding-right: 20px;
    padding-bottom: 90px;
    padding-left: 20px;
  }
  .privacy-h1 {
    font-size: 32px;
  }
  .privacy-rich-text h2 {
    font-size: 28px;
  }
  .privacy-rich-text h3 {
    margin-top: 25px;
    margin-bottom: 7px;
    font-size: 19px;
    line-height: 1.3em;
  }
  .privacy-rich-text li {
    padding-bottom: 14px;
    line-height: 1.3em;
  }
  .privacy-title {
    padding-top: 29px;
    padding-bottom: 12px;
  }
  .thick-line.orange {
    max-width: 55px;
    min-height: 7px;
  }
}