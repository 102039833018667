.blurb-impact {
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn.primary {
  color: #fff;
  background-color: #2191fb;
}

.btn.primary:hover {
  background-color: #1766b0;
}

.btn.secondary {
  color: #333;
  margin-left: 20px;
  background-color: #fff;
}

.btn.secondary:hover {
  background-color: rgba(33, 145, 251, 0.15);
}

.contact-form {
  background-color: #fff;
  padding-top: 50px;
  z-index: 10;
}

.header-grid {
  grid-template-areas: ". .";
  -ms-grid-columns: 1.5fr 1fr;
  grid-template-columns: 1.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  padding-top: 120px;
}

.home-container {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.home-h1 {
  margin-bottom: 40px;
  font-family: Poppins, sans-serif;
  font-size: 56px;
  line-height: 1.4em;
}

.home-header {
  padding-bottom: 100px;
  min-height: calc(100vh - 303px);
}

.home-p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  line-height: 1.5em;
}

.home-p.blurb {
  max-width: 600px;
}

.image-large {
  max-width: 450px;
  padding-top: 20px;
  visibility: visible;
  opacity: 1;
  display: block;
  margin: 0 auto;
}

.image-small {
  visibility: hidden;
  opacity: 0;
  display: none;
  margin: 0 auto;
}

.form-container {
  max-width: 480px;
  margin-right: auto;
  margin-left: 20px;
}

.contact-form {
  margin-inline: auto;

  ul {
    padding: 0;
    margin: 0;
  }

  ul > * + * {
    margin-top: 2.5rem;
  }

  li {
    list-style: none;
  }

  label {
    color: rgb(71 85 105);
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375;
    letter-spacing: -0.025em;
    margin-bottom: 0.75rem;

    &::after {
      content: "*";
      color: rgb(100 116 139);
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left: 0.25rem;
    }
  }

  input {
    display: block;
    font-size: 1rem;
    padding: 0.75rem 1rem;
    border: solid 1px #e2e8f0;
    border-radius: 0.25rem;
    width: 100%;

    &:hover {
      border-color: #94a3b8;
    }

    &:focus {
      border-color: #2191fb;
      outline: solid rgba(33, 145, 251, 0.2);
    }

    &::placeholder {
      color: #64748b;
      opacity: 1;
    }
  }
}

.btn.primary.contact {
  width: 100%;
  transition: all;

  &:hover {
    border-color: #1766b0;
  }

  &:focus {
    outline: solid rgba(33, 145, 251, 0.4);
  }
}

#w-node-d63d91b1cd89-a2336e27 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-36fc909a4bd2-a2336e27 {
  -ms-grid-column-align: center;
  justify-self: center;
}

@media screen and (max-width: 1125px) {
  .header-grid {
    -ms-grid-columns: 2fr 1fr;
    grid-template-columns: 2fr 1fr;
  }
  .image-large {
    max-width: 310px;
  }
}

@media screen and (max-width: 991px) {
  .header-grid {
    grid-column-gap: 20px;
    -ms-grid-columns: 2.05fr 1.5fr;
    grid-template-columns: 2.05fr 1.5fr;
    padding-top: 140px;
  }
  .home-container {
    max-width: 100%;
  }
  .home-h1 {
    font-size: 40px;
  }
  .home-header-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .home-p {
    font-size: 20px;
  }
  .home-p.blurb {
    font-size: 20px;
  }
  .image-large {
    max-width: 100%;
  }
  .form-container {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .header-grid {
    grid-template-areas: "." ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .home-header {
    min-height: calc(100vh - 443px);
  }
  .image-large {
    max-width: 60%;
  }
}

@media screen and (max-width: 479px) {
  .header-grid {
    padding-top: 100px;
  }
  .home-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .home-h1 {
    font-size: 28px;
  }
  .home-header {
    min-height: calc(100vh - 400px)
  }
  .home-header-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
  .home-p.blurb {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .image-large {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
  .image-small {
    visibility: visible;
    opacity: 1;
    display: block;
    max-width: 75%;
    padding-top: 40px;
  }
  #w-node-d63d91b1cd89-a2336e27 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
}
