.bio-circle-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-transition: background-color 200ms ease, -webkit-transform 200ms ease;
  transition: background-color 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, background-color 200ms ease;
  transition: transform 200ms ease, background-color 200ms ease, -webkit-transform 200ms ease;
  color: #949494;
}

.bio-circle-button:hover {
  background-color: #636363;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.contact-icon {
  width: 16px;
  height: 16px;
}

.contact-icon.blog {
  width: 12px;
  height: 12px;
}

.div-block-18 {
  min-height: 60px;
}

.image-4 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
}

.image-5 {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.lead-magnet {
  max-width: 670px;
  margin: 0 auto;
  padding-top: 100px;
}

.post-author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  float: left;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  color: #888;
  font-size: 16px;
  font-weight: 500;
}

.post-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 750px;
  margin: 20px auto 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.post-header {
  display: block;
  max-width: 750px;
  margin: 0px auto 34px;
  padding-top: 56px;
}

.post-image {
  display: block;
  width: 750px;
  margin-right: auto;
  margin-left: auto;
}

.post-rich-text {
  padding-bottom: 0px;
  padding-top: 40px;
  color: #333;
}

.post-rich-text p {
  margin-bottom: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #333;
  font-size: 19px;
  line-height: 1.6em;
  font-weight: 400;
}

.post-rich-text h2 {
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: Poppins, sans-serif;
  font-size: 34px;
  line-height: 1.3em;
  font-weight: 600;
}

.post-rich-text h4 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #333;
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 600;
}

.post-rich-text h3 {
  padding-top: 30px;
  padding-bottom: 15px;
  font-family: Poppins, sans-serif;
  line-height: 1.3em;
  font-weight: 600;
}

.post-rich-text blockquote {
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: none;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 400;
}

.post-rich-text a {
  border-bottom: 2px solid #2191fb;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #333;
  text-decoration: none;
}

.post-rich-text a:hover {
  border-bottom-width: 2px;
  border-bottom-color: #2191fb;
  background-color: rgba(33, 145, 251, 0.25);
}

.post-rich-text li {
  padding-bottom: 13px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.6em;
}

.post-rich-text figcaption {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

.post-rich-text img {
  display: block;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 45px;
}

.post-title {
  margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 42px;
  line-height: 1.3em;
  text-align: left;
}

.text-block-10 {
  display: block;
  padding-left: 10px;
  color: #7a7a7a;
}

@media screen and (max-width: 991px) {
  .container-8 {
    padding-right: 30px;
    padding-left: 30px;
  }
  .image-4 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .post-header {
    padding-right: 30px;
    padding-left: 30px;
  }
  .post-image {
    max-width: 100%;
    padding-right: 52px;
    padding-left: 52px;
  }
  .post-title {
    font-size: 38px;
    line-height: 1.3em;
  }
}

@media screen and (max-width: 767px) {
  .container-7 {
    padding-right: 25px;
    padding-left: 25px;
  }
  .container-8 {
    padding-right: 25px;
    padding-left: 25px;
  }
  .image-5 {
    height: 40px;
    width: 40px;
  }
  .post-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 750px;
    margin: 12px auto 25px;
    padding-left: 6px;
    float: none;
    clear: none;
  }
  .post-header {
    margin-top: 0px;
    margin-bottom: 18px;
    padding-top: 22px;
    padding-bottom: 0px;
  }
  .post-image {
    padding-right: 25px;
    padding-left: 25px;
  }
  .post-rich-text p {
    font-size: 18px;
  }
  .post-rich-text h2 {
    font-size: 30px;
  }
  .post-title {
    font-size: 32px;
    line-height: 1.2em;
  }
  .text-block-10 {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {
  .container-7 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .container-8 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .post-header {
    padding-right: 0px;
    padding-left: 0px;
  }
  .post-image {
    padding-right: 20px;
    padding-left: 20px;
  }
  .post-rich-text p {
    font-size: 17px;
  }
  .post-rich-text h2 {
    font-size: 24px;
  }
  .post-rich-text h4 {
    font-size: 18px;
  }
  .post-rich-text h3 {
    font-size: 20px;
  }
  .post-rich-text figure {
    max-width: 100%;
  }
  .post-title {
    font-size: 26px;
  }
}