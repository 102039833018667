.footer-button-list-item {
  margin-bottom: 10px;
}

.footer-circle-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-transition: background-color 200ms ease, -webkit-transform 200ms ease;
  transition: background-color 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, background-color 200ms ease;
  transition: transform 200ms ease, background-color 200ms ease, -webkit-transform 200ms ease;
}

.footer-circle-button:hover {
  background-color: #000;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.footer-copyright {
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
}

.footer---light {
  padding: 80px 10px 120px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ebebeb;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.footer-link-header {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}

.footer-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-list {
  line-height: 1.5em;
}

.grid-12 {
  grid-column-gap: 20px;
  -ms-grid-columns: 4fr 1fr 1fr 1fr;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-35 {
  max-width: 150px;
  margin-bottom: 20px;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.image-35:hover {
  opacity: 1;
}

.link-block-10 {
  text-decoration: none;
}

.link-block-10:hover {
  color: rgba(0, 0, 0, 0.6);
}

.text-block-33 {
  padding-bottom: 10px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: rgba(0, 0, 0, 0.6);
}

.text-block-33:hover {
  color: #000;
}

#w-node-c979d9b8cc03-d9b8cbe1 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

@media screen and (max-width: 991px) {
  .footer---light {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  .grid-12 {
    grid-row-gap: 60px;
    -ms-grid-columns: 2fr 2fr 1fr;
    grid-template-columns: 2fr 2fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .image-35 {
    max-width: 130px;
  }
  #w-node-c979d9b8cbe4-d9b8cbe1 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
  }
  #w-node-c979d9b8cbeb-d9b8cbe1 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  #w-node-c979d9b8cbfb-d9b8cbe1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-c979d9b8cc03-d9b8cbe1 {
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
}

@media screen and (max-width: 479px) {
  .footer---light {
    padding: 60px 0px 100px;
  }
  .grid-12 {
    grid-row-gap: 60px;
    -ms-grid-columns: 2fr 2fr 1fr;
    grid-template-columns: 2fr 2fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .image-35 {
    max-width: 120px;
  }
  #w-node-c979d9b8cbe4-d9b8cbe1 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
  }
  #w-node-c979d9b8cbeb-d9b8cbe1 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-c979d9b8cbfb-d9b8cbe1 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-c979d9b8cc03-d9b8cbe1 {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
}
